import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PreviewIcon from '@material-ui/icons/Visibility';
import { MovieRounded, SubdirectoryArrowLeft } from '@material-ui/icons'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import firebase from "firebase";
import ReactPlayer from 'react-player'
import AutoFitImage from 'react-image-autofit-frame';
import CheckedCircle from '@material-ui/icons/CheckCircle';
import HighlightOff from '@material-ui/icons/HighlightOff';
import ClearIcon from '@material-ui/icons/Cached';
import EjectIcon from '@material-ui/icons/Eject';
import RefreshIcon from '@material-ui/icons/Refresh';
import CustomizedCampaignList from './CustomizedCampaignList'
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import { directoryMode } from '../firebase/config';
import app from "../firebase/base";


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, rows } = this.props;
    
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false }
              >
                <Tooltip
                  title="Sort"
                  disableTouchListener={row.sortable ? false : true }
                  disableFocusListener={row.sortable ? false : true }
                  disableHoverListener={row.sortable ? false : true }
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={(orderBy === row.id && row.sortable) ? true : false }
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, onMoveAllClick, onDeleteAllClick, movefile } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          movefile ? <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Move">
                <IconButton aria-label="Move" onClick={onMoveAllClick}>
                <SubdirectoryArrowLeft />
                </IconButton>
            </Tooltip>
            { firebase.auth().currentUser.email == "support@pgkmediagroup.com" ? <Tooltip title="Delete">
                <IconButton aria-label="Delete" onClick={onDeleteAllClick}>
                <DeleteIcon />
                </IconButton>
            </Tooltip> : null }
            </div> : 
            <div style={{ display: 'flex', alignItems: 'center' }}>
            { firebase.auth().currentUser.email == "support@pgkmediagroup.com" ? <Tooltip title="Delete">
                <IconButton aria-label="Delete" onClick={onDeleteAllClick}>
                <DeleteIcon />
                </IconButton>
            </Tooltip> : null }
            </div>
        ) : (
          <div></div>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  actionButtons: {
    display: 'flex'
  }
});

class EnhancedTable extends React.Component {

  state = {
    order: 'desc',
    orderBy: this.props.orderBy,
    selected: [],
    selectedId: '',
    page: 0,
    rowsPerPage: 20,
    openDialog: false,
    dialogTitle: 'No Title',
    dialogUrl: null,
    dialogType: null,
    dialogOption: '',
    selectedFileName: '',
    selectedZone: this.props.selectedZone,
    campaignData: [],
    selectedCampaign: null,
    openSecondDialog: false,
    fullScreenDialog: false,
    selectedTableCell: '',
    subZoneData: [],
    selectedSubZone: 'default',
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState({ selected: this.props.data.map(n => n.id) })
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  onPreviewClick = (type, name, url) => {
    this.setState({
        openDialog: true,
        dialogTitle: name,
        dialogUrl: url,
        dialogType: type,
        dialogOption: 'preview',
        fullScreenDialog: false
    });
  }

  onEditClick = (id, name) => {

    const currentStationIndex = this.props.data.map((val)=>{ return val.id }).indexOf(id)
    const currentCampaignIndex = this.state.campaignData.map((val)=> { return val.id }).indexOf(this.props.data[currentStationIndex].campaign)

    const subZoneData = []
    
    app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref('station_zone/' + this.props.selectedZone + "/sub_zone").on('value', stationZoneSnapshot => { 

      stationZoneSnapshot.forEach(stationZoneChildSnapshot => {
        const item = stationZoneChildSnapshot.val();
        item.key = stationZoneChildSnapshot.key;
        subZoneData.push({ id:item.key, created: item.created, total: item.total, name: item.name })
      })

      

      this.setState({
        openDialog: true,
        dialogTitle: "Edit Station",
        selectedId: id,
        dialogOption: 'edit',
        selectedFileName: name,
        selectedZone: this.props.selectedZone,
        selectedCampaign: this.state.campaignData[currentCampaignIndex],
        fullScreenDialog: false,
        selectedSubZone: this.props.data[currentStationIndex].sub_zone ? this.props.data[currentStationIndex].sub_zone : "default",
        subZoneData
    });
    })
    
  }

  onDeleteClick = (id) => {
    this.setState({
        openDialog: true,
        dialogTitle: "Remove Station",
        selectedId: id,
        dialogOption: 'delete',
        fullScreenDialog: false
    });
  }

  onDeleteAllClick = () => {
    this.setState({
        openDialog: true,
        dialogOption: 'deleteAll',
        dialogTitle: "Remove file",
        fullScreenDialog: false
    });
  }

  onMoveAllClick = () => {
    

    const subZoneData = []
    app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref('station_zone/' + this.props.selectedZone + "/sub_zone").on('value', stationZoneSnapshot => { 

      stationZoneSnapshot.forEach(stationZoneChildSnapshot => {
        const item = stationZoneChildSnapshot.val();
        item.key = stationZoneChildSnapshot.key;
        subZoneData.push({ id:item.key, created: item.created, total: item.total, name: item.name })
      })

      this.setState({
        openDialog: true,
        dialogOption: 'moveAll',
        dialogTitle: "Move file",
        fullScreenDialog: false,
        selectedZone: this.props.selectedZone,
        selectedSubZone: 'default',
        subZoneData
      });
    })
  }

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  handleDialogClose = () => {
    this.setState({ openSecondDialog: false  });
  }

  _handleEditFileNameChange = e => {
    this.setState({
        selectedFileName: e.target.value
    });
  }

  handleSelectionChange = event => {
    const subZoneData = []
    app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref('station_zone/' + event.target.value + "/sub_zone").on('value', stationZoneSnapshot => { 

      stationZoneSnapshot.forEach(stationZoneChildSnapshot => {
        const item = stationZoneChildSnapshot.val();
        item.key = stationZoneChildSnapshot.key;
        subZoneData.push({ id:item.key, created: item.created, total: item.total, name: item.name })
      })

      this.setState({ selectedZone: event.target.value, subZoneData, selectedSubZone: 'default' });
    })
  };

  CustomizedSubZoneData = event => {

    this.setState({ selectedSubZone: event.target.value });
  };

  handleEditFile = () => {
    app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref().child('/station/' + this.state.selectedId).update({ name: this.state.selectedFileName, zone: this.state.selectedZone, campaign: this.state.selectedCampaign ? this.state.selectedCampaign.id : null, sub_zone: this.state.selectedSubZone });

    if(this.state.selectedZone !== this.props.selectedZone){

      if(this.state.selectedZone !== 'default'){
          app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref().child('/station_zone/' + this.state.selectedZone).child('total').transaction( total => {
          
          return total + 1;
          })

      }

      app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref().child('/station_zone/' + this.props.selectedZone).child('total').transaction( total => {
        
          return total - 1;
      })
    } else {
      if(this.state.selectedSubZone !== 'default'){
        app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref().child('/station_zone/' + this.state.selectedZone + "/sub_zone/" + this.state.selectedSubZone).child('total').transaction( total => {
          
          return total + 1;
        })
      }
    }

    this.setState({
        openDialog: false,
        selectedZone: this.props.selectedZone,
        selectedFileName: '',
        selected: [],
        selectedSubZone: 'default',
        subZoneData: []
    });

  }

  handleRemoveFile = () => {

    app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref(this.props.dataName).child(this.state.selectedId).once('value', snapshot => {
      const item = snapshot.val();

      if(item.zone !== 'default'){
        app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref('station_zone/' + item.zone).child('total').transaction( total => {
          
          return total - 1;
        })
      }

      app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref(this.props.dataName).child(this.state.selectedId).remove()
      app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref("push_station").child(this.state.selectedId).remove()
    })

    this.setState({
        openDialog: false,
        selectedId: '',
        selected: []
    });
    
  }

  handleRemoveAllFile = () => {
    this.state.selected.forEach(items => {
        app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref(this.props.dataName).child(items).once('value', snapshot => {
        const item = snapshot.val();

        if(item.zone !== 'default'){
          app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref('station_zone/' + item.zone).child('total').transaction( total => {
            
            return total - 1;
          })
        }

        app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref(this.props.dataName).child(items).remove()
        app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref("push_station").child(items).remove()
        })
    })

    this.setState({
        openDialog: false,
        selected: []
    });
  }

  handleMoveAllFile = () => {
    
    if(this.state.selectedZone !== this.props.selectedZone){
      this.state.selected.forEach(items => {
        app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref().child('/station/' + items).update({ zone: this.state.selectedZone, sub_zone: this.state.selectedSubZone });
  
        if(this.state.selectedZone !== 'default'){
          app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref().child('/station_zone/' + this.state.selectedZone).child('total').transaction( total => {
            
            return total + 1;
          })

          if(this.state.selectedSubZone !== 'default')
          {
            app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref().child('/station_zone/' + this.state.selectedZone + '/sub_zone/' + this.state.selectedSubZone).child('total').transaction( total => {
        
              return total + 1;
            })
          }
        }

        app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref().child('/station_zone/' + this.props.selectedZone).child('total').transaction( total => {
          
          return total - 1;
        })
        
      })
    } else {

      if(this.state.selectedSubZone !== 'default'){
        this.state.selected.forEach(items => {

          app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref().child('/station/' + items).update({ sub_zone: this.state.selectedSubZone });

          app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref().child('/station_zone/' + this.state.selectedZone + "/sub_zone/" + this.state.selectedSubZone).child('total').transaction( total => {
              
            return total + 1;
          })
          
        })
      }
    }

    this.setState({
        openDialog: false,
        selectedZone: 'default',
        selected: []
    });

  }

  renderDialog = () => {

    if(this.state.dialogOption === 'preview'){
        return(
          <DialogContent style={{ maxWidth: 500, maxHeight: 500, marginTop: 20 }}>
              { this.state.dialogType === 'video/mp4' ? <ReactPlayer width='100%' height="100%"  playing={true} url={this.state.dialogUrl}></ReactPlayer> : <AutoFitImage frameWidth={450 + "px"} frameHeight={450 + "px"} imgSize="contain" imgSrc={this.state.dialogUrl} /> }
          </DialogContent>
        )
    } 

    else if(this.state.dialogOption === 'edit'){
        return(
            <div>
            <DialogContent>
                <TextField
                    margin="dense"
                    id="name"
                    label="File Name"
                    fullWidth
                    value={this.state.selectedFileName} 
                    onChange={this._handleEditFileNameChange}
                />
                <FormControl style={{ marginTop: 20 }} fullWidth variant="outlined" noValidate autoComplete="off">
                  <InputLabel
                  ref={ref => {
                      this.InputLabelRef = ref;
                  }}
                  htmlFor="outlined-age-simple"
                  >
                  Zone
                  </InputLabel>
                  <Select
                  value={this.state.selectedZone}
                  onChange={this.handleSelectionChange}
                  input={
                      <OutlinedInput
                      labelWidth={100}
                      name="zone"
                      id="outlined-age-simple"
                      />
                  }
                  >
                  
                  <MenuItem value="default">
                      <em>Default</em>
                  </MenuItem>
                  {this.props.zoneCollection.map(n => {
                      return(
                      <MenuItem key={n.id} value={n.id}>{n.name}</MenuItem>
                      )
                  })}

                  </Select>
              </FormControl>
              <FormControl style={{ marginTop: 20 }} fullWidth variant="outlined" noValidate autoComplete="off" >
                    <InputLabel>
                        SubZone
                    </InputLabel>
                    <Select
                        disabled={this.state.subZoneData.length > 0 ? false : true}
                        value={this.state.selectedSubZone}
                        onChange={this.CustomizedSubZoneData}
                        input={
                        <OutlinedInput
                            labelWidth={100}
                            name="zone"
                            id="outlined-age-simple"
                        />
                        }
                    >
                        <MenuItem value="default">
                        <em>Default</em>
                        </MenuItem>
                        {this.state.subZoneData.map(n => {
                            return(
                                <MenuItem key={n.id} value={n.id}>{n.name}</MenuItem>
                            )
                        })}

                    </Select>
                </FormControl>
                <Button style={{marginTop: 10}} fullWidth variant="outlined" color="primary" onClick={this.handleSelectCampaign}>
                    {this.state.selectedCampaign ? this.state.selectedCampaign.name : 'Select Campaign'}
                </Button>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleEditFile} color="primary">
                Submit
                </Button>
                <Button onClick={this.handleClose} color="default">
                Cancel
                </Button>
            </DialogActions>
            </div>
        )
    }

    else if(this.state.dialogOption === 'delete'){
        return(
            <div>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Are you sure you want to permanently delete these file?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleRemoveFile} color="default">
                    Yes
                    </Button>
                    <Button color="primary" onClick={this.handleClose}>
                    No
                    </Button>
                </DialogActions>
            </div>
        )
    }

    else if(this.state.dialogOption === 'deleteAll'){
        return(
            <div>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Are you sure you want to permanently delete these file?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleRemoveAllFile} color="default">
                    Yes
                    </Button>
                    <Button color="primary" onClick={this.handleClose}>
                    No
                    </Button>
                </DialogActions>
            </div>
        )
    }

    else if(this.state.dialogOption === 'restart'){
      return(
          <div>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  Are you sure you want to restart these station?
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={this.handleRestartStation} color="default">
                  Yes
                  </Button>
                  <Button color="primary" onClick={this.handleClose}>
                  No
                  </Button>
              </DialogActions>
          </div>
      )
    } 

    else if(this.state.dialogOption === 'reset'){
      return(
          <div>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  Are you sure you want to reset this station?
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={this.handleResetStation} color="default">
                  Yes
                  </Button>
                  <Button color="primary" onClick={this.handleClose}>
                  No
                  </Button>
              </DialogActions>
          </div>
      )
    } 

    else if(this.state.dialogOption === 'update'){
      return(
          <div>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  Are you sure you want to update this station?
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={this.handleUpdateStation} color="default">
                  Yes
                  </Button>
                  <Button color="primary" onClick={this.handleClose}>
                  No
                  </Button>
              </DialogActions>
          </div>
      )
    } 

    else if(this.state.dialogOption === 'moveAll'){
        return(
            <div>
            <DialogContent style={{ width: 200}}>
            <FormControl style={{ marginTop: 20 }} fullWidth variant="outlined" noValidate autoComplete="off">
                  <InputLabel
                  ref={ref => {
                      this.InputLabelRef = ref;
                  }}
                  htmlFor="outlined-age-simple"
                  >
                  Zone
                  </InputLabel>
                  <Select
                  value={this.state.selectedZone}
                  onChange={this.handleSelectionChange}
                  input={
                      <OutlinedInput
                      labelWidth={100}
                      name="zone"
                      id="outlined-age-simple"
                      />
                  }
                  >
                  
                  <MenuItem value="default">
                      <em>Default</em>
                  </MenuItem>
                  {this.props.zoneCollection.map(n => {
                      return(
                      <MenuItem key={n.id} value={n.id}>{n.name}</MenuItem>
                      )
                  })}

                  </Select>
              </FormControl>
              <FormControl style={{ marginTop: 20 }} fullWidth variant="outlined" noValidate autoComplete="off" >
                    <InputLabel>
                        SubZone
                    </InputLabel>
                    <Select
                        disabled={this.state.subZoneData.length > 0 ? false : true}
                        value={this.state.selectedSubZone}
                        onChange={this.CustomizedSubZoneData}
                        input={
                        <OutlinedInput
                            labelWidth={100}
                            name="zone"
                            id="outlined-age-simple"
                        />
                        }
                    >
                        <MenuItem value="default">
                        <em>Default</em>
                        </MenuItem>
                        {this.state.subZoneData.map(n => {
                            return(
                                <MenuItem key={n.id} value={n.id}>{n.name}</MenuItem>
                            )
                        })}

                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleMoveAllFile} color="primary">
                Yes
                </Button>
                <Button onClick={this.handleClose} color="primary" autoFocus>
                No
                </Button>
            </DialogActions>
            </div>
        )
    }
      
  }

  handleSelectCampaign = () => {
    this.setState({ openSecondDialog: true});
  }

  componentWillMount() {
    let ref = app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref('campaign');

    ref.on('value', snapshot => {
      const campaignData = [];

      snapshot.forEach(childSnapshot => {
        const item = childSnapshot.val();
        item.key = childSnapshot.key;

        campaignData.push({ id:item.key, name: item.name, created: item.created, updated: item.updated, template: item.template, template_name: item.template_name , content: item.content});
        
      });

      this.setState({ campaignData });
    });
  }

  onRefreshClick = (id) =>{
    app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref('push_station/' + id ).update({ push : true, message: "push" })
    app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref('station/' + id ).update({ push_date: firebase.database.ServerValue.TIMESTAMP })
  }
  
  onRefreshWithoutDeleteClick = (id) =>{
    app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref('push_station/' + id ).update({ push : true, message: "no_delete_push" })
    app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref('station/' + id ).update({ push_date: firebase.database.ServerValue.TIMESTAMP })
  }

  onClearRefreshClick = (id) =>{
    this.setState({
      openDialog: true,
      dialogTitle: "Push & Clear Station",
      selectedId: id,
      dialogOption: 'clear',
      fullScreenDialog: false
    });
  }

  onRestartClick = (id) =>{
    this.setState({
      openDialog: true,
      dialogTitle: "Restart Station",
      selectedId: id,
      dialogOption: 'restart',
      dialogFullscreen: false
    });
  }

  onUpdateClick = (id) =>{
    this.setState({
      openDialog: true,
      dialogTitle: "Update Station App",
      selectedId: id,
      dialogOption: 'update',
      dialogFullscreen: false
    });
  }

  handleUpdateStation = () => {
    app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref('push_station/' + this.state.selectedId ).update({ push : true, message: "clear_push" })

    this.setState({
      openDialog: false,
      selectedId: '',
      selected: []
    });
  }

  onResetClick = (id) =>{
    this.setState({
      openDialog: true,
      dialogTitle: "Reset Station",
      selectedId: id,
      dialogOption: 'reset',
      fullScreenDialog: false
    });
  }

  handleResetStation = () => {
    app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref('push_station/' + this.state.selectedId ).update({ push : true, message: "restart" })

    this.setState({
      openDialog: false,
      selectedId: '',
      selected: []
    });
  }

  handleRestartStation = () => {
    app.database(directoryMode[0].mode ? "https://bethany-ds-default-rtdb.firebaseio.com/":undefined ).ref('push_station/' + this.state.selectedId ).update({ push : true, message: "restart" })

    this.setState({
      openDialog: false,
      selectedId: '',
      selected: []
    });
  }

  handleSelectedCampaign = id => {

    const currentIndex = this.state.campaignData.map(val => { return val.id}).indexOf(id)

    this.setState({ openSecondDialog: false,  selectedCampaign: this.state.campaignData[currentIndex]});
  }

  handleToggle = (event, id) => { 

    if(id === this.state.selectedTableCell){
      this.setState({ selectedTableCell: ''})
    } else {
      this.setState({ selectedTableCell: id})
    }
    
  }

  render() {
    const { classes, rows, data } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div className={classes.root}>
        <Dialog
            onClose={this.handleDialogClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.openSecondDialog}
            maxWidth='xl'
          >
          <DialogTitle id="customized-dialog-title" onClose={this.handleDialogClose}>
            Campaign List
          </DialogTitle> 
          <DialogContent >
            <CustomizedCampaignList 
              rows={[
                { id: 'name', numeric: false, disablePadding: false, label: 'Campaign Name', sortable: true },
                { id: 'template', numeric: false, disablePadding: false, label: 'Template', sortable: true },
              ]} 
              data={this.state.campaignData}
              orderBy={'created'}
              movefile={false}
              dataName='campaign'
              handleSelectedCampaign={this.handleSelectedCampaign}/>
          </DialogContent>
        </Dialog>
        <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.openDialog}
          >
          {this.state.dialogOption  !== 'preview' ?
              <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                {this.state.dialogTitle}
              </DialogTitle> : <div></div>
            }
          {this.renderDialog()}
        </Dialog>
        <EnhancedTableToolbar numSelected={selected.length} onDeleteAllClick={this.onDeleteAllClick} onMoveAllClick={this.onMoveAllClick} movefile={this.props.movefile}/>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              rows={rows}
            />

            <TableCell padding={'none'}  colSpan={6}>
                <FormControl style={{ marginTop:10 }}  fullWidth >
                  <InputLabel style={{ marginLeft:20 }}>Search</InputLabel>
                  <Input onChange={(e) => {this.props.search(e)}}
                    id="input-with-icon-adornment"
                    startAdornment={
                      <InputAdornment style={{ marginLeft:20 }} position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
            </TableCell>

            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  let selectedCampaign = 'none'
                  let totalCampaignContent = 'null'
                      const currentCampaignIndex = this.state.campaignData.map(val => { return val.id}).indexOf(n.campaign)
                      if(currentCampaignIndex !== -1){
                        selectedCampaign = this.state.campaignData[currentCampaignIndex].name
                        totalCampaignContent = this.state.campaignData[currentCampaignIndex].content[0].length
                      }

                  return (
                    [<TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell onClick={event => this.handleClick(event, n.id)} padding="checkbox">
                        <Checkbox checked={isSelected} />
                      </TableCell>

                      <TableCell onClick={event => this.handleToggle(event, n.id)} align="left" padding="none">{n.name}</TableCell>
                      {n.connected ? <TableCell onClick={event => this.handleToggle(event, n.id)} padding={'default'} align="left" padding="default"><CheckedCircle style={{ color: 'green'}} fontSize="small" /></TableCell> :
                      <TableCell onClick={event => this.handleToggle(event, n.id)} padding={'default'} align="left" padding="default"><HighlightOff color="secondary"  fontSize="small" /></TableCell>
                      }

                      {!this.props.isMobile ? n.is_playing ? <TableCell onClick={event => this.handleToggle(event, n.id)} padding={'default'} align="left" padding="default"><CheckedCircle style={{ color: 'green'}} fontSize="small" /></TableCell> :
                      <TableCell onClick={event => this.handleToggle(event, n.id)} padding={'default'} align="left" padding="default"><HighlightOff color="secondary"  fontSize="small" /></TableCell>
                       : ""}

                      <TableCell onClick={event => this.handleToggle(event, n.app_version)} align="left" padding="default">{n.app_version}</TableCell>

                      {!this.props.isMobile ? <TableCell align="left" padding="none" >
                      <div className={classes.actionButtons}>
                      <Tooltip title="Refresh">
                            <IconButton onClick={() => this.onRefreshClick(n.id) }>
                                <RefreshIcon fontSize="small" />
                            </IconButton>
                            </Tooltip>
                            
                            <Tooltip title="Edit">
                            <IconButton onClick={() => this.onEditClick(n.id, n.name) }>
                                <EditIcon fontSize="small" />
                            </IconButton>
                            </Tooltip>
                            { firebase.auth().currentUser.email == "support@pgkmediagroup.com" ?  <Tooltip title="Delete">
                            <IconButton onClick={() => this.onDeleteClick(n.id) }>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                            </Tooltip> : null }
                        </div>
                        </TableCell> : ''}
                     
                    </TableRow>, 
                    <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Id</TableCell>
                      <TableCell padding={'none'} colSpan={11}>{n.id}</TableCell>
                    </TableRow>, 
                    <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Campaign</TableCell>
                      <TableCell padding={'none'} colSpan={11}>{selectedCampaign}</TableCell>
                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Created by</TableCell>
                      <TableCell padding={'none'} colSpan={11}>{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(n.created)}</TableCell>
                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Updated by</TableCell>
                      <TableCell padding={'none'} colSpan={11}>{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(n.updated)}</TableCell>
                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                    <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Pushed by</TableCell>
                    <TableCell padding={'none'} colSpan={11}>{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(n.push_date)}</TableCell>
                  </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>SIM Serial</TableCell>
                      <TableCell padding={'none'} colSpan={11}>{n.sim_serial}</TableCell>
                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Total of file (App)</TableCell>
                      <TableCell padding={'none'} colSpan={11}>{n.total_file_in_app}</TableCell>
                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Total of file (campaign)</TableCell>
                      <TableCell padding={'none'} colSpan={11}>{totalCampaignContent}</TableCell>
                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id && this.props.isMobile ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell padding={'default'} colSpan={12}>
                        <div className={classes.actionButtons}>
                            <IconButton onClick={() => this.onRefreshClick(n.id) }>
                                <RefreshIcon fontSize="small" />
                            </IconButton>
                            <IconButton onClick={() => this.onRestartClick(n.id) }>
                                <ClearIcon fontSize="small"/>
                            </IconButton>
                            <IconButton onClick={() => this.onEditClick(n.id, n.name) }>
                                <EditIcon fontSize="small" />
                            </IconButton>
                            { firebase.auth().currentUser.email == "support@pgkmediagroup.com" ? <IconButton onClick={() => this.onDeleteClick(n.id) }>
                                <DeleteIcon fontSize="small" />
                            </IconButton> : null }
                        </div>
                      </TableCell>
                    </TableRow>]
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[20, 50, 200]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable);