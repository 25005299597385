import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PreviewIcon from '@material-ui/icons/Visibility';
import { MovieRounded, SubdirectoryArrowLeft } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import firebase from "firebase";
import ReactPlayer from 'react-player'
import AutoFitImage from 'react-image-autofit-frame';
import CheckedCircle from '@material-ui/icons/CheckCircle';
import HighlightOff from '@material-ui/icons/HighlightOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import CustomizedDropzone from './CustomizedDropzone'
import InfoIcon from '@material-ui/icons/Info';
import { directoryMode } from '../firebase/config';
import app from "../firebase/base";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, rows } = this.props;
    
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false }
              >
                <Tooltip
                  title="Sort"
                  disableTouchListener={row.sortable ? false : true }
                  disableFocusListener={row.sortable ? false : true }
                  disableHoverListener={row.sortable ? false : true }
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={(orderBy === row.id && row.sortable) ? true : false }
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, onMoveAllClick, onDeleteAllClick, movefile } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          movefile ? <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Move">
                <IconButton aria-label="Move" onClick={onMoveAllClick}>
                <SubdirectoryArrowLeft />
                </IconButton>
            </Tooltip>
            { firebase.auth().currentUser.email == "support@pgkmediagroup.com" ? <Tooltip title="Delete">
                <IconButton aria-label="Delete" onClick={onDeleteAllClick}>
                <DeleteIcon />
                </IconButton>
            </Tooltip> : null }
            </div> : 
            <div style={{ display: 'flex', alignItems: 'center' }}>
            { firebase.auth().currentUser.email == "support@pgkmediagroup.com" ? <Tooltip title="Delete">
                <IconButton aria-label="Delete" onClick={onDeleteAllClick}>
                <DeleteIcon />
                </IconButton>
            </Tooltip> : null }
            </div>
        ) : (
          <div></div>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  actionButtons: {
    display: 'flex'
  }
});

class EnhancedTable extends React.Component {

  state = {
    order: 'desc',
    orderBy: this.props.orderBy,
    selected: [],
    selectedId: '',
    page: 0,
    rowsPerPage: 20,
    openDialog: false,
    dialogTitle: 'No Title',
    dialogUrl: null,
    dialogType: null,
    dialogOption: '',
    selectedFileName: '',
    selectedNoted: '',
    selectedFolder: this.props.selectedFolder,
    selectedTableCell: '',
    campaignCollection: [],
    campaignAdsCollection: [],
    selectedSubFolder: 'default',
    subfolderData: [],
    currentData: [],
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState({ selected: this.props.data.map(n => n.id) })
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  onPreviewClick = (type, name, url) => {
    this.setState({
        openDialog: true,
        dialogTitle: name,
        dialogUrl: url,
        dialogType: type,
        dialogOption: 'preview'
    });
  }

  onEditClick = (id, name, noted) => {

    const subfolderData = []

    if(directoryMode[0].mode){
      app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref('media_folder/' + this.props.selectedFolder + "/sub_folder").on('value', mediaFolderSnapshot => { 

        mediaFolderSnapshot.forEach(mediaFolderChildSnapshot => {
          const item = mediaFolderChildSnapshot.val();
          item.key = mediaFolderChildSnapshot.key;
          subfolderData.push({ id:item.key, created: item.created, total: item.total, name: item.name })
        })
  
        this.setState({
          openDialog: true,
          dialogTitle: "Edit file",
          selectedId: id,
          dialogOption: 'edit',
          selectedFileName: name,
          selectedNoted: noted ? noted : '',
          selectedFolder: this.props.selectedFolder,
          selectedSubFolder: 'default',
          subfolderData
        });
      })
    }
    else
    {
      app.database().ref('media_folder/' + this.props.selectedFolder + "/sub_folder").on('value', mediaFolderSnapshot => { 

        mediaFolderSnapshot.forEach(mediaFolderChildSnapshot => {
          const item = mediaFolderChildSnapshot.val();
          item.key = mediaFolderChildSnapshot.key;
          subfolderData.push({ id:item.key, created: item.created, total: item.total, name: item.name })
        })
  
        this.setState({
          openDialog: true,
          dialogTitle: "Edit file",
          selectedId: id,
          dialogOption: 'edit',
          selectedFileName: name,
          selectedNoted: noted ? noted : '',
          selectedFolder: this.props.selectedFolder,
          selectedSubFolder: 'default',
          subfolderData
        });
      })
    }

  }

  onInfoClick = (id, name, noted) => {
    this.setState({
        openDialog: true,
        dialogTitle: "Info",
        selectedId: id,
        dialogOption: 'info',

    });
  }

  onDeleteClick = (id) => {
    this.setState({
        openDialog: true,
        dialogTitle: "Remove file",
        selectedId: id,
        dialogOption: 'delete'
    });
  }

  onDeleteAllClick = () => {
    this.setState({
        openDialog: true,
        dialogOption: 'deleteAll',
        dialogTitle: "Remove file",
    });
  }

  onMoveAllClick = () => {

    const subfolderData = []
    
    if(directoryMode[0].mode)
    {
      app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref('media_folder/' + this.props.selectedFolder + "/sub_folder").on('value', mediaFolderSnapshot => { 

        mediaFolderSnapshot.forEach(mediaFolderChildSnapshot => {
          const item = mediaFolderChildSnapshot.val();
          item.key = mediaFolderChildSnapshot.key;
          subfolderData.push({ id:item.key, created: item.created, total: item.total, name: item.name })
        })
  
        this.setState({
          openDialog: true,
          dialogOption: 'moveAll',
          dialogTitle: "Move file",
          selectedFolder: this.props.selectedFolder,
          selectedSubFolder: 'default',
          subfolderData
        });
      })
    }
    else
    {
      app.database().ref('media_folder/' + this.props.selectedFolder + "/sub_folder").on('value', mediaFolderSnapshot => { 

        mediaFolderSnapshot.forEach(mediaFolderChildSnapshot => {
          const item = mediaFolderChildSnapshot.val();
          item.key = mediaFolderChildSnapshot.key;
          subfolderData.push({ id:item.key, created: item.created, total: item.total, name: item.name })
        })
  
        this.setState({
          openDialog: true,
          dialogOption: 'moveAll',
          dialogTitle: "Move file",
          selectedFolder: this.props.selectedFolder,
          selectedSubFolder: 'default',
          subfolderData
        });
      })
    }
    

    
  }

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  _handleEditFileNameChange = e => {
    this.setState({
        selectedFileName: e.target.value
    });
  }

  _handleEditNotedChange = e => {
    this.setState({
        selectedNoted: e.target.value
    });
  }

  handleSelectionChange = event => {

    const subfolderData = []

    if(directoryMode[0].mode)
    {
      app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref('media_folder/' + event.target.value + "/sub_folder").on('value', mediaFolderSnapshot => { 

        mediaFolderSnapshot.forEach(mediaFolderChildSnapshot => {
          const item = mediaFolderChildSnapshot.val();
          item.key = mediaFolderChildSnapshot.key;
          subfolderData.push({ id:item.key, created: item.created, total: item.total, name: item.name })
        })
  
        this.setState({ selectedFolder: event.target.value, subfolderData, selectedSubFolder: 'default' });
      })
    }
    else
    {
      app.database().ref('media_folder/' + event.target.value + "/sub_folder").on('value', mediaFolderSnapshot => { 

        mediaFolderSnapshot.forEach(mediaFolderChildSnapshot => {
          const item = mediaFolderChildSnapshot.val();
          item.key = mediaFolderChildSnapshot.key;
          subfolderData.push({ id:item.key, created: item.created, total: item.total, name: item.name })
        })
  
        this.setState({ selectedFolder: event.target.value, subfolderData, selectedSubFolder: 'default' });
      })
    }
    

  };

  handleSubfolderSelectionChange = event => {

    this.setState({ selectedSubFolder: event.target.value });
  };

  handleEditFile = () => {
    if(directoryMode[0].mode)
    {
      app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref().child('/media/' + this.state.selectedId).update({ name: this.state.selectedFileName, folder: this.state.selectedFolder, noted: this.state.selectedNoted, sub_folder: this.state.selectedSubFolder });
    }
    else
    {
      app.database().ref().child('/media/' + this.state.selectedId).update({ name: this.state.selectedFileName, folder: this.state.selectedFolder, noted: this.state.selectedNoted, sub_folder: this.state.selectedSubFolder });
    }

    if(this.state.selectedFolder !== this.props.selectedFolder){

      if(this.state.selectedFolder !== 'default'){

         /*app.database().ref().child('/media_folder/' + this.state.selectedFolder).child('total').transaction( total => {
          
            return total + 1;
            })*/
          
      }


      /*firebase.database().ref().child('/media_folder/' + this.props.selectedFolder).child('total').transaction( total => {
        
          return total - 1;
      })*/
    } else {
      if(this.state.selectedSubFolder !== 'default'){
        /*firebase.database().ref().child('/media_folder/' + this.state.selectedFolder + "/sub_folder/" + this.state.selectedSubFolder).child('total').transaction( total => {
          
          return total + 1;
        })*/
      }
    }

    this.setState({
        openDialog: false,
        selectedFolder: this.props.selectedFolder,
        selectedFileName: '',
        selectedNoted: '',
        selected: [],
        selectedSubFolder: 'default',
        subfolderData: [],
    });

    
  }

  handleRemoveFile = () => {

    if(directoryMode[0].mode)
    {
      app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref(this.props.dataName).child(this.state.selectedId).once('value', snapshot => {
        const item = snapshot.val();
  
        if(item.folder !== 'default'){
          
          app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref('media_folder/' + item.folder).child('total').transaction( total => {
            
            return total - 1;
          })
  
        }
  
        app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref(this.props.dataName).child(this.state.selectedId).remove().then(() => {
          
          firebase.storage().ref().child(item.fullPath).delete()
  
        })
      })
    }
    else
    {
      app.database().ref(this.props.dataName).child(this.state.selectedId).once('value', snapshot => {
        const item = snapshot.val();
  
        if(item.folder !== 'default'){
          
          app.database().ref('media_folder/' + item.folder).child('total').transaction( total => {
            
            return total - 1;
          })
  
        }
  
        app.database().ref(this.props.dataName).child(this.state.selectedId).remove().then(() => {
          
          firebase.storage().ref().child(item.fullPath).delete()
  
        })
      })
    }
    

    this.setState({
        openDialog: false,
        selectedId: '',
        selected: []
    });
    
  }

  handleRemoveAllFile = () => {
    this.state.selected.forEach(items => {
      
        const campaign_content = []

        this.state.campaignCollection.map(val => {
          val.content.map(contentContainerVal =>{
            contentContainerVal.map(contentVal=> {
              if(contentVal.content === items){
                campaign_content.push({ content: contentVal.content, campaign: val.id, name: val.name })
              }
            })
          })
        })

        const currentIndex = campaign_content.map(val => { return val.content }).indexOf(items)

        if(currentIndex === -1){

          if(directoryMode[0].mode){
            app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref(this.props.dataName).child(items).once('value', snapshot => {
              const item = snapshot.val();
      
              if(item.folder !== 'default'){
                app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref('media_folder/' + item.folder).child('total').transaction( total => {
                  
                  return total - 1;
                })
              }
      
              app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref(this.props.dataName).child(items).remove().then(() => {
                  
                  firebase.storage().ref().child(item.fullPath).delete()
      
                })
              })
          }
          else
          {
            app.database().ref(this.props.dataName).child(items).once('value', snapshot => {
              const item = snapshot.val();
      
              if(item.folder !== 'default'){
                app.database().ref('media_folder/' + item.folder).child('total').transaction( total => {
                  
                  return total - 1;
                })
              }
      
              app.database().ref(this.props.dataName).child(items).remove().then(() => {
                  
                  firebase.storage().ref().child(item.fullPath).delete()
      
                })
              })
          }
          
        }

    })

    this.setState({
        openDialog: false,
        selected: []
    });
  }

  handleMoveAllFile = () => {
    
    if(this.state.selectedFolder !== this.props.selectedFolder){
      this.state.selected.forEach(items => {

        if(directoryMode[0].mode)
        {
          app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref().child('/media/' + items).update({ folder: this.state.selectedFolder, sub_folder: this.state.selectedSubFolder });
        }
        else
        {
          app.database().ref().child('/media/' + items).update({ folder: this.state.selectedFolder, sub_folder: this.state.selectedSubFolder });
        }
  
        /*if(this.state.selectedFolder !== 'default'){
          firebase.database().ref().child('/media_folder/' + this.state.selectedFolder).child('total').transaction( total => {
            
            return total + 1;
          })

          if(this.state.selectedSubFolder !== 'default')
          {
            firebase.database().ref().child('/media_folder/' + this.state.selectedFolder + '/sub_folder/' + this.state.selectedSubFolder).child('total').transaction( total => {
        
              return total + 1;
            })
          }
        }

        firebase.database().ref().child('/media_folder/' + this.props.selectedFolder).child('total').transaction( total => {
          
          return total - 1;
        })*/
        
      })
    } else {

      if(this.state.selectedSubFolder !== 'default'){
        this.state.selected.forEach(items => {

          if(directoryMode[0].mode)
          {
            app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref().child('/media/' + items).update({ sub_folder: this.state.selectedSubFolder });

            app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref().child('/media_folder/' + this.state.selectedFolder + "/sub_folder/" + this.state.selectedSubFolder).child('total').transaction( total => {
                
              return total + 1;
            })
          }
          else
          {
            app.database().ref().child('/media/' + items).update({ sub_folder: this.state.selectedSubFolder });

            app.database().ref().child('/media_folder/' + this.state.selectedFolder + "/sub_folder/" + this.state.selectedSubFolder).child('total').transaction( total => {
                
              return total + 1;
            })
          }
         
          
        })
      }
      
    }

    this.setState({
        openDialog: false,
        selectedFolder: 'default',
        selected: [],
        selectedSubFolder: 'default',
        subfolderData: []
    });

  }

  renderDialog = () => {

    if(this.state.dialogOption === 'preview'){
        return(
          <DialogContent style={{ marginTop: 20, height:  1920/4, width:1080/4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                { this.state.dialogType === 'video/mp4' ? <ReactPlayer width='100%' height="100%"  playing={true} url={this.state.dialogUrl}></ReactPlayer> : <AutoFitImage imgSize="contain" imgSrc={this.state.dialogUrl} /> }
          </DialogContent>
        )
    } 

    else if(this.state.dialogOption === 'edit'){
        return(
            <div>
            <DialogContent>
                <TextField
                    margin="dense"
                    id="name"
                    label="File Name"
                    fullWidth
                    value={this.state.selectedFileName} 
                    onChange={this._handleEditFileNameChange}
                />
                <TextField
                    margin="dense"
                    id="noted"
                    label="Noted"
                    fullWidth
                    value={this.state.selectedNoted} 
                    onChange={this._handleEditNotedChange}
                />
                <FormControl style={{ marginTop: 20 }} fullWidth variant="outlined" noValidate autoComplete="off" >
                    <InputLabel
                        ref={ref => {
                        this.InputLabelRef = ref;
                        }}
                        htmlFor="outlined-age-simple"
                    >
                        Folder
                    </InputLabel>
                    <Select
                        value={this.state.selectedFolder}
                        onChange={this.handleSelectionChange}
                        input={
                        <OutlinedInput
                            labelWidth={100}
                            name="folder"
                            id="outlined-age-simple"
                        />
                        }
                    >
                        
                        <MenuItem value="default">
                        <em>Default</em>
                        </MenuItem>
                        {this.props.folderCollection.map(n => {
                            return(
                                <MenuItem key={n.id} value={n.id}>{n.name}</MenuItem>
                            )
                        })}

                    </Select>
                </FormControl>
                <FormControl style={{ marginTop: 20 }} fullWidth variant="outlined" noValidate autoComplete="off" >
                    <InputLabel>
                        SubFolder
                    </InputLabel>
                    <Select
                        disabled={this.state.subfolderData.length > 0 ? false : true}
                        value={this.state.selectedSubFolder}
                        onChange={this.handleSubfolderSelectionChange}
                        input={
                        <OutlinedInput
                            labelWidth={100}
                            name="folder"
                            id="outlined-age-simple"
                        />
                        }
                    >
                        <MenuItem value="default">
                        <em>Default</em>
                        </MenuItem>
                        {this.state.subfolderData.map(n => {
                            return(
                                <MenuItem key={n.id} value={n.id}>{n.name}</MenuItem>
                            )
                        })}

                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleEditFile} color="primary">
                Submit
                </Button>
                <Button onClick={this.handleClose} color="default">
                Cancel
                </Button>
            </DialogActions>
            </div>
        )
    }

    else if(this.state.dialogOption === 'info'){


      var id =  this.props.data.map(val=> { return val.id }).indexOf(this.state.selectedId);

      const campaign_content = []

      this.state.campaignCollection.map(val => {
        val.content.map(contentContainerVal =>{
          contentContainerVal.map(contentVal=> {
            if(contentVal.content === this.state.selectedId){
              campaign_content.push({ content: contentVal.content, campaign: val.id, name: val.name })
            }
          })
        })
      })

      const currentIndex = campaign_content.map(val => { return val.content }).indexOf(this.state.selectedId)

      const campaign_used = []
      const campaign_used_name = []
      campaign_content.map(val => {
        if(campaign_used.indexOf(val.campaign) === -1){
          campaign_used.push(val.campaign)
          campaign_used_name.push(val.name)
          
        }
      })

      return(
          <div>
              <DialogContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="right"></TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Id
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {this.props.data[id].id}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                      Uploaded Date
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(this.props.data[id].created)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                      File Type
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {this.props.data[id].type}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                      File Size
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {this.bytesToSize(this.props.data[id].size)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                      In Use
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {currentIndex !== -1 ? <Tooltip title={<div>{campaign_used_name.map(val=> { return <div>{val}<br /></div> })}</div>}><CheckedCircle style={{ color: 'green'}} fontSize="small" /></Tooltip> : <HighlightOff color="secondary"  fontSize="small" />}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                      User Uploaded
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {this.props.data[id].user}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                      Noted
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {this.props.data[id].noted}
                      </TableCell>
                    </TableRow>
                    
                  </TableBody>
                </Table>
              </DialogContent>
          </div>
      )
    }

    else if(this.state.dialogOption === 'delete'){
        return(
            <div>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Are you sure you want to permanently delete these file?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleRemoveFile} color="default">
                    Yes
                    </Button>
                    <Button color="primary" onClick={this.handleClose}>
                    No
                    </Button>
                </DialogActions>
            </div>
        )
    }

    else if(this.state.dialogOption === 'deleteAll'){
        return(
            <div>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Are you sure you want to permanently delete these file?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleRemoveAllFile} color="default">
                    Yes
                    </Button>
                    <Button color="primary" onClick={this.handleClose}>
                    No
                    </Button>
                </DialogActions>
            </div>
        )
    }

    else if(this.state.dialogOption === 'moveAll'){
        return(
            <div>
            <DialogContent style={{ width: 200}}>
                <FormControl style={{ marginTop: 20 }} fullWidth variant="outlined" noValidate autoComplete="off">
                    <InputLabel
                    ref={ref => {
                        this.InputLabelRef = ref;
                    }}
                    htmlFor="outlined-age-simple"
                    >
                    Folder
                    </InputLabel>
                    <Select
                    value={this.state.selectedFolder}
                    onChange={this.handleSelectionChange}
                    input={
                        <OutlinedInput
                        labelWidth={100}
                        name="folder"
                        id="outlined-age-simple"
                        />
                    }
                    >
                    
                    <MenuItem value="default">
                        <em>Default</em>
                    </MenuItem>
                    {this.props.folderCollection.map(n => {
                        return(
                        <MenuItem key={n.id} value={n.id}>{n.name}</MenuItem>
                        )
                    })}

                    </Select>
                </FormControl>
                <FormControl style={{ marginTop: 20 }} fullWidth variant="outlined" noValidate autoComplete="off" >
                    <InputLabel>
                        SubFolder
                    </InputLabel>
                    <Select
                        disabled={this.state.subfolderData.length > 0 ? false : true}
                        value={this.state.selectedSubFolder}
                        onChange={this.handleSubfolderSelectionChange}
                        input={
                        <OutlinedInput
                            labelWidth={100}
                            name="folder"
                            id="outlined-age-simple"
                        />
                        }
                    >
                        <MenuItem value="default">
                        <em>Default</em>
                        </MenuItem>
                        {this.state.subfolderData.map(n => {
                            return(
                                <MenuItem key={n.id} value={n.id}>{n.name}</MenuItem>
                            )
                        })}

                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleMoveAllFile} color="primary">
                Yes
                </Button>
                <Button onClick={this.handleClose} color="primary" autoFocus>
                No
                </Button>
            </DialogActions>
            </div>
        )
    }
      
  }

  componentDidMount(){

    if(directoryMode[0].mode)
    {
      let refCampaign = app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref('campaign');

      let refAdsCampaign = app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref('campaign_ads');

      refCampaign.once('value', campaignSnapshot => {
        const campaignCollection = [];
  
        campaignSnapshot.forEach(CampaignChildSnapshot => {
          const item = CampaignChildSnapshot.val();
          item.key = CampaignChildSnapshot.key;
          
          campaignCollection.push({id:item.key, created: item.created, updated: item.updated, template: item.template, template_name: item.template_name, name: item.name, content: item.content });
          
        });

        refAdsCampaign.once('value', campaignSnapshot1 => {
          const campaignAdsCollection = [];
    
          campaignSnapshot1.forEach(CampaignChildSnapshot1 => {
            const item1 = CampaignChildSnapshot1.val();
            item1.key = CampaignChildSnapshot1.key;
            
            campaignAdsCollection.push({id:item1.key, created: item1.created, 
              updated: item1.updated, template: item1.template, template_name: item1.template_name, 
              name: item1.name, content: item1.content });
            
          });
    
          this.setState({ campaignCollection, campaignAdsCollection });
        });

      });
    }
    else{
      let refCampaign = app.database().ref('campaign');

      let refAdsCampaign = app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref('campaign_ads');

      refCampaign.once('value', campaignSnapshot => {
        const campaignCollection = [];
  
        campaignSnapshot.forEach(CampaignChildSnapshot => {
          const item = CampaignChildSnapshot.val();
          item.key = CampaignChildSnapshot.key;
          
          campaignCollection.push({id:item.key, 
            created: item.created, 
            updated: item.updated, 
            template: item.template, 
            template_name: item.template_name, 
            name: item.name, content: item.content });
          
        });

        refAdsCampaign.once('value', campaignSnapshot1 => {
          const campaignAdsCollection = [];
    
          campaignSnapshot1.forEach(CampaignChildSnapshot1 => {
            const item1 = CampaignChildSnapshot1.val();
            item1.key = CampaignChildSnapshot1.key;
            
            campaignAdsCollection.push({id:item1.key, 
              created: item1.created, 
              updated: item1.updated, 
              template: item1.template, 
              template_name: item1.template_name, 
              name: item1.name, content: item1.content });
            
          });
    
          this.setState({ campaignCollection, campaignAdsCollection });
        });

      });
    }
    
  }

  handleToggle = (event, id) => { 

    if(id === this.state.selectedTableCell){
      this.setState({ selectedTableCell: ''})
    } else {
      this.setState({ selectedTableCell: id})
    }
    
  }

  render() {
    const { classes, rows, data } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div className={classes.root}>
         <CustomizedDropzone folder={this.props.selectedFolder} sub_folder={null}/>
        <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.openDialog}
          >
            
          {this.state.dialogOption  !== 'preview' ?
              <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                {this.state.dialogTitle}
              </DialogTitle> : <div></div>
            }

          {this.renderDialog()}
        </Dialog>
        <EnhancedTableToolbar numSelected={selected.length} onDeleteAllClick={this.onDeleteAllClick} onMoveAllClick={this.onMoveAllClick} movefile={this.props.movefile}/>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              rows={rows}
            />
            <TableBody>
              <TableCell padding={'none'}  colSpan={5}>
                <FormControl style={{ marginTop:10 }}  fullWidth >
                  <InputLabel style={{ marginLeft:20 }}>Search</InputLabel>
                  <Input onChange={(e) => {this.props.search(e)}}
                    id="input-with-icon-adornment"
                    startAdornment={
                      <InputAdornment style={{ marginLeft:20 }} position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </TableCell>
                {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  const campaign_content = []

                  this.state.campaignCollection.map(val => {
                    val.content.map(contentContainerVal =>{
                      contentContainerVal.map(contentVal=> {
                        if(contentVal.content === n.id){
                          campaign_content.push({ content: contentVal.content, campaign: val.id, name: val.name })
                        }
                      })
                    })
                  })

                  this.state.campaignAdsCollection.map(val => {
                    val.content.map(contentContainerVal =>{
                      contentContainerVal.map(contentVal=> {
                        if(contentVal.content === n.id){
                          campaign_content.push({ content: contentVal.content, campaign: val.id, name: val.name })
                        }
                      })
                    })
                  })

                  const currentIndex = campaign_content.map(val => { return val.content }).indexOf(n.id)

                  const campaign_used = []
                  const campaign_used_name = []
                  campaign_content.map(val => {
                    if(campaign_used.indexOf(val.campaign) === -1){
                      campaign_used.push(val.campaign)
                      campaign_used_name.push(val.name)
                      
                    }
                  })

                  return (
                    [<TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                     
                      <TableCell onClick={event => this.handleClick(event, n.id)} padding="checkbox">
                        <Checkbox checked={isSelected} />
                      </TableCell>

                      <TableCell onClick={event => this.props.select ? this.handleClick(event, n.id) : this.handleToggle(event, n.id)} align="left" padding="none">{n.name}</TableCell>
                      
                      <TableCell onClick={event => this.props.select ? this.handleClick(event, n.id) : this.handleToggle(event, n.id)} align="left" padding="default">
                          {(n.type === 'video/mp4') ? <div style={{ height: 50}} ><MovieRounded style={{ fontSize: 50, color: "#b4b4b4" }}/></div> : <img style={{ height: 50}} src={n.imageUrl} alt=""/>}
                      </TableCell>

                      {!this.props.isMobile  ? <TableCell align="left" padding="default">
                        {currentIndex !== -1 ? <Tooltip title={<div>{campaign_used_name.map(val=> { return <div>{val}<br /></div> })}</div>}><CheckedCircle style={{ color: 'green'}} fontSize="small" /></Tooltip> : <HighlightOff color="secondary"  fontSize="small" />}
                      </TableCell> : null}

                      {!this.props.isMobile ? <TableCell align="left" padding="none" >
                      <div className={classes.actionButtons}>
                      <Tooltip title="Preview">
                            <IconButton color="primary" onClick={() => this.onPreviewClick(n.type, n.name, n.imageUrl) }>
                                <PreviewIcon fontSize="small" />
                            </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit">
                            <IconButton onClick={() => this.onEditClick(n.id, n.name, n.noted) }>
                                <EditIcon fontSize="small" />
                            </IconButton>
                            </Tooltip>
                            <Tooltip title="Info">
                            <IconButton onClick={() => this.onInfoClick(n.id) }>
                                <InfoIcon fontSize="small" />
                            </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                            <IconButton disabled={currentIndex !== -1 ? true : false} onClick={() => this.onDeleteClick(n.id) }>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                            </Tooltip>
                        </div>
                      </TableCell> : ''}

                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Id</TableCell>
                      <TableCell padding={'none'} colSpan={11}>{n.id}</TableCell>
                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Uploaded Date</TableCell>
                      <TableCell padding={'none'} colSpan={11}>{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(n.created)}</TableCell>
                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>File Type</TableCell>
                      <TableCell padding={'none'} colSpan={11}>{n.type}</TableCell>
                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>File Size</TableCell>
                      <TableCell padding={'none'} colSpan={11}>{this.bytesToSize(n.size)}</TableCell>
                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>In Use</TableCell>
                      <TableCell padding={'none'} colSpan={11}>{currentIndex !== -1 ? <Tooltip title={<div>{campaign_used_name.map(val=> { return <div>{val}<br /></div> })}</div>}><CheckedCircle style={{ color: 'green'}} fontSize="small" /></Tooltip> : <HighlightOff color="secondary"  fontSize="small" />}</TableCell>
                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>User Uploaded</TableCell>
                      <TableCell padding={'none'} colSpan={11}>{n.user}</TableCell>
                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Noted</TableCell>
                      <TableCell padding={'none'} colSpan={11}>{n.noted}</TableCell>
                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id && this.props.isMobile ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell padding={'default'} colSpan={12}>
                        <div className={classes.actionButtons}>
                            <IconButton color="primary" onClick={() => this.onPreviewClick(n.type, n.name, n.imageUrl) }>
                                <PreviewIcon fontSize="small" />
                            </IconButton>
                            <IconButton onClick={() => this.onEditClick(n.id, n.name, n.noted) }>
                                <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton disabled={currentIndex !== -1 ? true : false} onClick={() => this.onDeleteClick(n.id) }>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>]
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[20, 50, 200]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable);