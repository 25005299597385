import firebase from "firebase";

//pgkmedia
/*const app = firebase.initializeApp({
    apiKey: "AIzaSyB63DckIx7uIcVk-rkbuOTRVMkLWQfhqGE",
    authDomain: "pgkmedia-ds.firebaseapp.com",
    databaseURL: "https://pgkmedia-ds.firebaseio.com",
    projectId: "pgkmedia-ds",
    storageBucket: "pgkmedia-ds.appspot.com",
    messagingSenderId: "114496602761",
    appId: "1:114496602761:web:93af1f1e4a57bd64"
});*/

//ntuc
/*const app = firebase.initializeApp({
    apiKey: "AIzaSyAt7IOdNd5jXc6K5vg7evAd3C1iLty-5wk",
    authDomain: "ntuc-5c91b.firebaseapp.com",
    databaseURL: "https://ntuc-5c91b.firebaseio.com",
    projectId: "ntuc-5c91b",
    storageBucket: "ntuc-5c91b.appspot.com",
    messagingSenderId: "535054178687",
    appId: "1:535054178687:web:433b0032f6f1e64e"
});*/

//youtv
/*const app = firebase.initializeApp({
    apiKey: "AIzaSyDaNusNsQRZQ47uGJFZg5zxCgNoQMxdEc0",
    authDomain: "youtv-3b886.firebaseapp.com",
    databaseURL: "https://youtv-3b886.firebaseio.com",
    projectId: "youtv-3b886",
    storageBucket: "youtv-3b886.appspot.com",
    messagingSenderId: "1087436996081",
    appId: "1:1087436996081:web:346e403013660cd5"
});*/

//jctkiosk
/*const app = firebase.initializeApp({
    apiKey: "AIzaSyCXqQ3X_zXk_3n_8eOm7zqNdRNM5CR8Kwo",
    authDomain: "jctkiosk.firebaseapp.com",
    databaseURL: "https://jctkiosk.firebaseio.com",
    projectId: "jctkiosk",
    storageBucket: "jctkiosk.appspot.com",
    messagingSenderId: "964087026709",
    appId: "1:964087026709:web:50fced6aca078bd5"
});*/

//suntec
/*const app = firebase.initializeApp({
    apiKey: "AIzaSyA4mWx1I7QjI5S1DFZQhf5l8VU3gznd2LY",
    authDomain: "suntec-f5bcf.firebaseapp.com",
    databaseURL: "https://suntec-f5bcf.firebaseio.com",
    projectId: "suntec-f5bcf",
    storageBucket: "suntec-f5bcf.appspot.com",
    messagingSenderId: "634776043794",
    appId: "1:634776043794:web:c8f210e8433e1119c573cb"
});*/

//Bethany
const app = firebase.initializeApp({
    apiKey: "AIzaSyDjY9S184pgWr2T9iLLPxienjNwrwXh96U",
    authDomain: "bethany-ds.firebaseapp.com",
    databaseURL: "https://bethany-ds-default-rtdb.firebaseio.com",
    projectId: "bethany-ds",
    storageBucket: "bethany-ds.appspot.com",
    messagingSenderId: "942646766579",
    appId: "1:942646766579:web:606b5973259b0942078086"
});

//dummy
/*const app = firebase.initializeApp({
    apiKey: "AIzaSyA5USL_w3vOoooQMYNEQl9fGzlpr7IHbJ8",
    authDomain: "suntec-lobbies-dummy.firebaseapp.com",
    databaseURL: "https://suntec-lobbies-dummy-default-rtdb.firebaseio.com",
    projectId: "suntec-lobbies-dummy",
    storageBucket: "suntec-lobbies-dummy.appspot.com",
    messagingSenderId: "1016389175832",
    appId: "1:1016389175832:web:32d231f9bcd0f02046116d"
});*/


export default app;