import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import DeleteIcon from '@material-ui/icons/Delete';
import PreviewIcon from '@material-ui/icons/Visibility';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import firebase from "firebase";
import Slide from '@material-ui/core/Slide';
import ReactPlayer from 'react-player';
import Paper from '@material-ui/core/Paper';
import AutoFitImage from 'react-image-autofit-frame';
import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom';
import Grow from '@material-ui/core/Grow';
import Grid from '@material-ui/core/Grid';
import ReactTimeout from 'react-timeout'
import { compose } from "recompose";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { directoryMode } from '../firebase/config';
import app from "../firebase/base";

var timeout = [];

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, rows } = this.props;
    
    return (
      <TableHead>
        <TableRow>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false }
              >
                <Tooltip
                  title="Sort"
                  disableTouchListener={row.sortable ? false : true }
                  disableFocusListener={row.sortable ? false : true }
                  disableHoverListener={row.sortable ? false : true }
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={(orderBy === row.id && row.sortable) ? true : false }
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, onDeleteAllClick, movefile } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          movefile ? <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Delete">
                <IconButton aria-label="Delete" onClick={onDeleteAllClick}>
                <DeleteIcon />
                </IconButton>
            </Tooltip> 
            </div> : 
            <Tooltip title="Delete">
                <IconButton aria-label="Delete" onClick={onDeleteAllClick}>
                <DeleteIcon />
                </IconButton>
            </Tooltip> 
        ) : (
          <div></div>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  actionButtons: {
    display: 'flex'
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  AppBar: {
    boxShadow: 'none',
    borderBottom: '1px solid #e8e8e8',
  },
  tab: {
    borderLeft: '1px solid #e8e8e8',
  },
  mediaContainer: {
    position: 'absolute',
  }
});

class EnhancedTable extends React.Component {

  state = {
    order: 'desc',
    orderBy: this.props.orderBy,
    selected: [],
    selectedId: '',
    page: 0,
    rowsPerPage: 5,
    openDialog: false,
    dialogTitle: 'No Title',
    folderId: '',
    folderName: '',
    dialogOption: '',
    selectedFileName: '',
    dialogFullscreen: false,
    editCampaign: false,
    editCampaignId: null,
    dialogTitleVisible: true,
    selectedCampaignId: null,
    campaignCollection: [],
    templateCollection: [],
    mediaCollection: [],
    contents: [[]],
    currentSlideIndex: [0],
    selectedTemplate: 'default',
    selectedTableCell: '',
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState({ selected: this.props.data.map(n => n.id) })
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    //this.setState({ selected: newSelected });
    this.props.handleSelectedCampaign(id)
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  onEditClick = (id) => {
    this.setState({ value: 2, editCampaign: true, editCampaignId: id  });
  }

  onDeleteClick = (id) => {
    this.setState({
        openDialog: true,
        dialogTitle: "Remove Campaign",
        selectedId: id,
        dialogOption: 'delete',
        dialogFullscreen: false
    });
  }

  onPreviewClick = (id) => {

    const currentIndex = this.state.campaignCollection.map(val => { return val.id }).indexOf(id)
   
    const currentTemplateIndex = this.state.templateCollection.map(val => { return val.id }).indexOf(this.state.campaignCollection[currentIndex].template)
    const contents = []
    const currentSlideIndex = []

    if(currentTemplateIndex !== -1)
    {
      this.state.templateCollection[currentTemplateIndex].container.map((value, index) => { 

        if(this.state.campaignCollection[currentIndex].content && this.state.campaignCollection[currentIndex].content[index])
        {
          contents.push(this.state.campaignCollection[currentIndex].content[index])
        } else {
          contents.push([])
        }
  
        currentSlideIndex.push(0)
      })
    } else {
      if(this.state.campaignCollection[currentIndex].content){
        contents.push(this.state.campaignCollection[currentIndex].content[0])
      } else {
        contents.push([])
      }
      
      currentSlideIndex.push(0)
    }

    this.state.contents = contents
    this.state.currentSlideIndex = currentSlideIndex
    this.state.selectedTemplate =  currentTemplateIndex !== -1 ? this.state.templateCollection[currentTemplateIndex].id : 'default'
    
    this.setState({ 
      openDialog: true, 
      dialogOption: 'preview', 
      dialogTitle: 'Preview',
      dialogTitleVisible: false,
      selectedCampaignId: id,
    })
    
    this.shiftSlide()
  }

  onDeleteAllClick = () => {
    this.setState({
        openDialog: true,
        dialogOption: 'deleteAll',
        dialogTitle: "Remove Campaign",
        dialogFullscreen: false
    });
  }

  handleClose = () => {
    const currentSlideIndex = []
    if(this.state.selectedTemplate !== 'default'){
      const currentIndex = this.state.templateCollection.map(val => { return val.id }).indexOf(this.state.selectedTemplate)
      this.state.templateCollection[currentIndex].container.map((val, index) => {
        clearTimeout(timeout[index])
        timeout[index] = null
        currentSlideIndex.push(0)
      })
    } else {
        clearTimeout(timeout[0])
        timeout[0] = null
        currentSlideIndex.push(0)
    }
      
    this.setState({ openDialog: false, currentSlideIndex });
  };

  _handleEditFileNameChange = e => {
    this.setState({
        selectedFileName: e.target.value
    });
  }

  handleSelectionChange = event => {
    this.setState({ selectedFolder: event.target.value });
  };

  handleEditFile = () => {

    if(directoryMode[0].mode){
      app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref().child('/media_folder/' + this.state.selectedId).update({ name: this.state.selectedFileName });

      this.setState({
          openDialog: false,
          selectedFolder: 'default',
          selectedFileName: '',
          selected: []
      });
    }
    else
    {
      app.database().ref().child('/media_folder/' + this.state.selectedId).update({ name: this.state.selectedFileName });

      this.setState({
          openDialog: false,
          selectedFolder: 'default',
          selectedFileName: '',
          selected: []
      });
    }
    
  }

  handleRemoveFile = () => {

    if(directoryMode[0].mode)
    {
      app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref(this.props.dataName).child(this.state.selectedId).remove()

      this.setState({
        openDialog: false,
        selectedId: '',
        selected: []
      });
    }
    else
    {
      app.database().ref(this.props.dataName).child(this.state.selectedId).remove()

      this.setState({
        openDialog: false,
        selectedId: '',
        selected: []
      });
    }
    
    
  }

  handleRemoveAllFile = () => {

    if(directoryMode[0].mode)
    {
      this.state.selected.forEach(items => {
        app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref(this.props.dataName).child(items).remove()
      })
    }
    else
    {
      this.state.selected.forEach(items => {
        app.database().ref(this.props.dataName).child(items).remove()
      })
    }
    

    this.setState({
      openDialog: false,
      selected: []
    });
  }

  renderDialog = () => {

    if(this.state.dialogOption === 'delete'){
        return(
            <div>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Are you sure you want to permanently delete these campaign?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleRemoveFile} color="default">
                    Yes
                    </Button>
                    <Button color="primary" onClick={this.handleClose}>
                    No
                    </Button>
                </DialogActions>
            </div>
        )
    }

    else if(this.state.dialogOption === 'deleteAll'){
        return(
            <div>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Are you sure you want to permanently delete these campaign?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleRemoveAllFile} color="default">
                    Yes
                    </Button>
                    <Button color="primary" onClick={this.handleClose}>
                    No
                    </Button>
                </DialogActions>
            </div>
        )
    } else if (this.state.dialogOption === 'preview'){

      if(this.state.selectedTemplate !== 'default'){

        const templateData = [];
        this.state.templateCollection.map((value, index) => {
          if(value.id === this.state.selectedTemplate)
          {
            value.container.map((val, index) => {
              templateData.push({ id:val.id, width: val.width, height: val.height })
            })
          }
        })

        return(
          <DialogContent style={{ backgroundColor: '#efefef', paddingTop: 25 }}>
            {templateData.map((value, index) => {
              return (
                  <Grid key={value.id} style={{height: value.height/4, width:value.width/4}} item>
                    {this.renderListAllCarouselItem( index, value.width/4, value.height/4 )}
                  </Grid>
                )
            })}
          </DialogContent>
        )
        
      } else {
        return (
          <DialogContent style={{ backgroundColor: '#efefef', paddingTop: 25 }}>
            <Grid style={{height: 1920/4, width: 1080/4}} item>
              {this.renderListAllCarouselItem(0, 1080/4, 1920/4)}
            </Grid>
          </DialogContent>)
      }
    }

  }

  renderListAllCarouselItem = (cIndex, itemWidth, itemHeight) => {
        return this.state.contents[cIndex].map((value, index) => { 

        const currentIndex = this.state.mediaCollection.map(val => { return val.id }).indexOf(value.content)

        if(value.transition === 'none'){
          return <Fade key={index} timeout={0} in={this.state.currentSlideIndex[cIndex] === index ? true : false} >
            <Paper square className={this.props.classes.mediaContainer} style={{ height: itemHeight, width:itemWidth, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            {this.state.mediaCollection[currentIndex].type === "video/mp4" ? <ReactPlayer width='100%' height="100%" loop={this.state.contents[cIndex].length > 1 ? false : true} playing={this.state.currentSlideIndex[cIndex] === index ? true : false} url={this.state.mediaCollection[currentIndex].imageUrl} onEnded={()=> this.toggleNextSlide(cIndex)}/>:<AutoFitImage imgSize="contain" imgSrc={this.state.mediaCollection[currentIndex].imageUrl}/>}
            </Paper>
          </Fade>
        } else if(value.transition === 'fade'){
          return <Fade key={index} timeout={1000} in={this.state.currentSlideIndex[cIndex] === index ? true : false} >
            <Paper square className={this.props.classes.mediaContainer} style={{ height: itemHeight, width:itemWidth, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            {this.state.mediaCollection[currentIndex].type === "video/mp4" ? <ReactPlayer width='100%' height="100%" loop={this.state.contents[cIndex].length > 1 ? false : true} playing={this.state.currentSlideIndex[cIndex] === index ? true : false} url={this.state.mediaCollection[currentIndex].imageUrl} onEnded={()=> this.toggleNextSlide(cIndex)}/>:<AutoFitImage imgSize="contain" imgSrc={this.state.mediaCollection[currentIndex].imageUrl}/>}
            </Paper>
          </Fade>
        } else if(value.transition === 'zoom'){
          return <Zoom key={index} timeout={1000} in={this.state.currentSlideIndex[cIndex] === index ? true : false} >
            <Paper square className={this.props.classes.mediaContainer} style={{ height: itemHeight, width:itemWidth, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            {this.state.mediaCollection[currentIndex].type === "video/mp4" ? <ReactPlayer width='100%' height="100%" loop={this.state.contents[cIndex].length > 1 ? false : true} playing={this.state.currentSlideIndex[cIndex] === index ? true : false} url={this.state.mediaCollection[currentIndex].imageUrl} onEnded={()=> this.toggleNextSlide(cIndex)}/>:<AutoFitImage imgSize="contain" imgSrc={this.state.mediaCollection[currentIndex].imageUrl}/>}
            </Paper>
          </Zoom>
        } else if(value.transition === 'grow'){
          return <Grow key={index} timeout={1000} in={this.state.currentSlideIndex[cIndex] === index ? true : false} >
            <Paper square className={this.props.classes.mediaContainer} style={{ height: itemHeight, width:itemWidth, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            {this.state.mediaCollection[currentIndex].type === "video/mp4" ? <ReactPlayer width='100%' height="100%" loop={this.state.contents[cIndex].length > 1 ? false : true} playing={this.state.currentSlideIndex[cIndex] === index ? true : false} url={this.state.mediaCollection[currentIndex].imageUrl} onEnded={()=> this.toggleNextSlide(cIndex)}/>:<AutoFitImage imgSize="contain" imgSrc={this.state.mediaCollection[currentIndex].imageUrl}/>}
            </Paper>
          </Grow >
        }


        
      })
  }

  toggleSlide = (index) => event =>{
      
      
    if(this.state.currentSlideIndex[index] < this.state.contents[index].length - 1){
      const newCurrentSlide = [...this.state.currentSlideIndex]
      newCurrentSlide[index] = newCurrentSlide[index] + 1
      this.setState({ currentSlideIndex: newCurrentSlide })

      const nextSlideIndex = newCurrentSlide[index]
      if(this.state.contents[index][nextSlideIndex].type !== 'video/mp4' ){
        this.loopSlide(index)
      }
    } else {
      const newCurrentSlide = [...this.state.currentSlideIndex]
      newCurrentSlide[index] = 0
      this.setState({ currentSlideIndex: newCurrentSlide })

      const nextSlideIndex = newCurrentSlide[index]
      if(this.state.contents[index][nextSlideIndex].type !== 'video/mp4' ){
        this.loopSlide(index)
      }
    }
   
  } 

  toggleNextSlide = (index) => {
    
    
    if(this.state.currentSlideIndex[index] < this.state.contents[index].length - 1){
      const newCurrentSlide = [...this.state.currentSlideIndex]
      newCurrentSlide[index] = newCurrentSlide[index] + 1
      this.setState({ currentSlideIndex: newCurrentSlide })

      const nextSlideIndex = newCurrentSlide[index]
      if(this.state.contents[index][nextSlideIndex].type !== 'video/mp4' ){
        this.loopNextSlide(index, nextSlideIndex)
      }
    } else {
      const newCurrentSlide = [...this.state.currentSlideIndex]
      newCurrentSlide[index] = 0
      this.setState({ currentSlideIndex: newCurrentSlide })

      const nextSlideIndex = newCurrentSlide[index]
      if(this.state.contents[index][nextSlideIndex].type !== 'video/mp4' ){
        this.loopNextSlide(index, nextSlideIndex)
      }
    }
   
  } 

  loopNextSlide = (index, nextSlideIndex) => {
    timeout[index] = this.props.setTimeout(this.toggleSlide(index, this.state.contents[index].length), this.state.contents[index][nextSlideIndex].duration * 1000)
  }

  loopSlide = (index) => {
    timeout[index] = this.props.setTimeout(this.toggleSlide(index, this.state.contents[index].length), this.state.contents[index][this.state.currentSlideIndex[index]].duration * 1000)
  }

  shiftSlide = () => {

    if(this.state.selectedTemplate !== 'default')
    {
      const currentIndex = this.state.templateCollection.map(val => { return val.id }).indexOf(this.state.selectedTemplate)
      this.state.templateCollection[currentIndex].container.map((val, index) => {
        if(this.state.contents[index].length > 1){
          if(this.state.contents[index][0].type !== 'video/mp4')
          {
            timeout[index] = this.props.setTimeout(this.toggleSlide(index), this.state.contents[index][this.state.currentSlideIndex[index]].duration * 1000)
          } 
        }
      })

    } else {
      if( this.state.contents[0].length > 1 && this.state.contents[0][0].type !== 'video/mp4'){
        timeout[0] = this.props.setTimeout(this.toggleSlide(0), this.state.contents[0][this.state.currentSlideIndex[0]].duration * 1000)
      }
    }
    
  }

  componentWillMount(){

    let campaignRef = directoryMode[0].mode ? app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref('campaign') : app.database().ref('campaign')

    campaignRef.on('value', campaignSnapshot => {
      const campaignCollection = [];

      campaignSnapshot.forEach(CampaignChildSnapshot => {
        const item = CampaignChildSnapshot.val();
        item.key = CampaignChildSnapshot.key;
        
        campaignCollection.push({id:item.key, created: item.created, updated: item.updated, template: item.template, template_name: item.template_name, name: item.name, content: item.content });
        
      });

      let templateRef = directoryMode[0].mode ? app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref('template') : app.database().ref('template');

      templateRef.once('value', templateSnapshot => {
          const templateCollection = [];

          templateSnapshot.forEach(templateChildSnapshot => {
              const templateItem = templateChildSnapshot.val();
              templateItem.key = templateChildSnapshot.key;
              templateCollection.push({ id:templateItem.key, name: templateItem.name, created: templateItem.created, container: templateItem.container });
          });

         
          let mediaRef = directoryMode[0].mode ? app.database("https://bethany-ds-default-rtdb.firebaseio.com/").ref('media') : app.database().ref('media');

          mediaRef.once('value', mediaSnapshot => {
              const mediaCollection = [];

              mediaSnapshot.forEach(mediaChildSnapshot => {
                const mediaItem = mediaChildSnapshot.val();
                mediaItem.key = mediaChildSnapshot.key;
                
                mediaCollection.push({id:mediaItem.key, name: mediaItem.name, imageUrl: mediaItem.imageUrl, created: mediaItem.created, type: mediaItem.type, size: mediaItem.size, folder: mediaItem.folder });
                
              });

              this.setState({ templateCollection, campaignCollection, mediaCollection})
          })
      });
      
    });
  }

  handleToggle = (event, id) => { 

    if(id === this.state.selectedTableCell){
      this.setState({ selectedTableCell: ''})
    } else {
      this.setState({ selectedTableCell: id})
    }
    
  }
  
  render() {
    const { classes, rows, data, theme } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    
    return (
      <div className={classes.root}>
        <Dialog
            fullScreen={this.state.dialogFullscreen}
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.openDialog}
            maxWidth='xl'
            TransitionComponent={Transition}
          >
            { this.state.dialogOption === 'preview' ?<div></div> :
           <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
              {this.state.dialogTitle}
            </DialogTitle> }
          {this.renderDialog()}
        </Dialog>
        <EnhancedTableToolbar numSelected={selected.length} onDeleteAllClick={this.onDeleteAllClick} movefile={this.props.movefile} />
            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={data.length}
                  rows={rows}
                />
                <TableCell padding={'none'}  colSpan={5}>
                    <FormControl style={{ marginTop:10 }}  fullWidth >
                      <InputLabel style={{ marginLeft:20 }}>Search</InputLabel>
                      <Input 
                        id="input-with-icon-adornment"
                        startAdornment={
                          <InputAdornment style={{ marginLeft:20 }} position="start">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                </TableCell>
                <TableBody>
                  {stableSort(data, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(n => {
                      const isSelected = this.isSelected(n.id);
                      return (
                        [<TableRow
                          hover
                          role="checkbox"
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={n.id}
                          selected={isSelected}
                        >
                          <TableCell onClick={event => this.props.handleSelectedCampaign(n.id)} align="left" padding="default">{n.name}</TableCell>
                      
                          <TableCell onClick={event => this.props.handleSelectedCampaign(n.id)} align="left" padding="default">
                            {n.template_name}
                          </TableCell>
                        </TableRow>]
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles, { withTheme: true }),
    ReactTimeout
)(EnhancedTable);